exports.components = {
  "component---content-uses-homelab-network-mdx": () => import("./../../../content/uses/homelab/network.mdx" /* webpackChunkName: "component---content-uses-homelab-network-mdx" */),
  "component---content-uses-homelab-plex-server-mdx": () => import("./../../../content/uses/homelab/plex-server.mdx" /* webpackChunkName: "component---content-uses-homelab-plex-server-mdx" */),
  "component---content-uses-office-computer-mdx": () => import("./../../../content/uses/office/computer.mdx" /* webpackChunkName: "component---content-uses-office-computer-mdx" */),
  "component---content-uses-office-fidget-mdx": () => import("./../../../content/uses/office/fidget.mdx" /* webpackChunkName: "component---content-uses-office-fidget-mdx" */),
  "component---content-uses-office-furniture-mdx": () => import("./../../../content/uses/office/furniture.mdx" /* webpackChunkName: "component---content-uses-office-furniture-mdx" */),
  "component---content-uses-outdoor-cooking-mdx": () => import("./../../../content/uses/outdoor-cooking.mdx" /* webpackChunkName: "component---content-uses-outdoor-cooking-mdx" */),
  "component---content-uses-software-ide-mdx": () => import("./../../../content/uses/software/ide.mdx" /* webpackChunkName: "component---content-uses-software-ide-mdx" */),
  "component---content-uses-software-terminal-mdx": () => import("./../../../content/uses/software/terminal.mdx" /* webpackChunkName: "component---content-uses-software-terminal-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-results-js": () => import("./../../../src/pages/contact-results.js" /* webpackChunkName: "component---src-pages-contact-results-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

